// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { CustomerRateModel } from '../_models/customerRate.model';

export enum CustomerRateActionTypes {
    CustomerRateOnServerCreated = '[Edit CustomerRate Dialog] CustomerRate On Server Created',
    CustomerRateCreated = '[Edit CustomerRate Dialog] CustomerRate Created',
    CustomerRateUpdated = '[Edit CustomerRate Dialog] CustomerRate Updated',
    CustomerRateStatusUpdated = '[CustomerRate List Page] CustomerRate Status Updated',
    OneCustomerRateDeleted = '[CustomerRate List Page] One CustomerRate Deleted',
    CustomerRatePageRequested = '[CustomerRate List Page] CustomerRate Page Requested',
    CustomerRatePageLoaded = '[CustomerRate API] CustomerRate Page Loaded',
    CustomerRatePageCancelled = '[CustomerRate API] CustomerRate Page Cancelled',
    CustomerRatePageToggleLoading = '[CustomerRate] CustomerRate Page Toggle Loading',
    CustomerRateActionToggleLoading = '[CustomerRate] CustomerRate Action Toggle Loading'
}

export class CustomerRateOnServerCreated implements Action {
    readonly type = CustomerRateActionTypes.CustomerRateOnServerCreated;
    constructor(public payload: { customerRate: CustomerRateModel }) { }
}

export class CustomerRateCreated implements Action {
    readonly type = CustomerRateActionTypes.CustomerRateCreated;
    constructor(public payload: { customerRate: CustomerRateModel }) { }
}

export class CustomerRateUpdated implements Action {
    readonly type = CustomerRateActionTypes.CustomerRateUpdated;
    constructor(public payload: {
        CustRateId: number,
        partialCustomerRate: Update<CustomerRateModel>, // For State update
        customerRate: CustomerRateModel
    }) { }
}

export class CustomerRateStatusUpdated implements Action {
    readonly type = CustomerRateActionTypes.CustomerRateStatusUpdated;
    constructor(public payload: {
        customerRate: CustomerRateModel[],
        ProductRate: number
    }) { }
}

export class OneCustomerRateDeleted implements Action {
    readonly type = CustomerRateActionTypes.OneCustomerRateDeleted;
    constructor(public payload: { CustRateId: number }) { }
}

export class CustomerRatePageRequested implements Action {
    readonly type = CustomerRateActionTypes.CustomerRatePageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class CustomerRatePageLoaded implements Action {
    readonly type = CustomerRateActionTypes.CustomerRatePageLoaded;
    constructor(public payload: { customerRates: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class CustomerRatePageCancelled implements Action {
    readonly type = CustomerRateActionTypes.CustomerRatePageCancelled;
}

export class CustomerRatePageToggleLoading implements Action {
    readonly type = CustomerRateActionTypes.CustomerRatePageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class CustomerRateActionToggleLoading implements Action {
    readonly type = CustomerRateActionTypes.CustomerRateActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type CustomerRateActions = CustomerRateOnServerCreated
    | CustomerRateCreated
    | CustomerRateUpdated
    | CustomerRateStatusUpdated
    | OneCustomerRateDeleted
    | CustomerRatePageRequested
    | CustomerRatePageLoaded
    | CustomerRatePageCancelled
    | CustomerRatePageToggleLoading
    | CustomerRateActionToggleLoading;
