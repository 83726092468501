// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { VehicleState } from '../_reducers/vehicle.reducers';
import { VehicleModel } from '../_models/vehicle.model';

export const selectVehicleState = createFeatureSelector<VehicleState>('vehicles');

export const selectVehicleById = (VehicleMId: number) => createSelector(
    selectVehicleState,
    vehicleState => vehicleState.entities[VehicleMId]
);

export const selectVehiclePageLoading = createSelector(
    selectVehicleState,
    vehicleState => vehicleState.listLoading
);

export const selectVehicleActionLoading = createSelector(
    selectVehicleState,
    vehicleState => vehicleState.actionsloading
);

export const selectLastCreatedVehicleMId = createSelector(
    selectVehicleState,
    vehicleState => vehicleState.lastCreatedVehicleMId
);

export const selectVehicleShowInitWaitingMessage = createSelector(
    selectVehicleState,
    vehicleState => vehicleState.showInitWaitingMessage
);

export const selectVehicleInStore = createSelector(
    selectVehicleState,
    vehicleState => {
        const items: VehicleModel[] = [];
        each(vehicleState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: VehicleModel[] = httpExtension.sortArray(items, vehicleState.lastQuery.sortField, vehicleState.lastQuery.sortOrder);
        return new QueryResultsModel(result, vehicleState.totalCount, '');
    }
);
