import { BaseModel } from '../../../_base/crud';

export class TransporterModel extends BaseModel {
	TransportMId: number;
	CompanyMId: number;
	TransCode: number;
	TransCompanyName: string;
	TransName: string;
	TransAddress: string;
	TransPincode: string;
	TransMobileNo: string;
	TransPhoneNo: string;
	TransFaxNo: string;
	TransEmailId: string;
	TransWebsite: string;
	TransRefPerson1: string;
	TransRefPerson1Mo: string;
	TransRefPerson1EmailId: string;
	TransRefPerson2: string;
	TransRefPerson2Mo: string;
	TransRefPerson2EmailId: string;
	TransAddInfo1: string;
	TransAddInfo2: string;
	TransAddInfo3: number;
	TransAddInfo4: number;


	clear() {
		this.CompanyMId = undefined;
		this.TransCode = undefined;
		this.TransCompanyName = '';
		this.TransName = '';
		this.TransAddress = '';
		this.TransPincode = '';
		this.TransMobileNo = '';
		this.TransPhoneNo = '';
		this.TransFaxNo = '';
		this.TransEmailId = '';
		this.TransWebsite = '';
		this.TransRefPerson1 = '';
		this.TransRefPerson1Mo = '';
		this.TransRefPerson1EmailId = '';
		this.TransRefPerson2 = '';
		this.TransRefPerson2Mo = '';
		this.TransRefPerson2EmailId = '';
		this.TransAddInfo1 = '';
		this.TransAddInfo2 = '';
		this.TransAddInfo3 = undefined;
		this.TransAddInfo4 = undefined;
	}
}
