// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { SiteActions, SiteActionTypes } from '../_actions/site.actions';
// Models
import { SiteModel } from '../_models/site.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface SiteState extends EntityState<SiteModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedSiteMId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SiteModel> = createEntityAdapter<SiteModel>({
    selectId: site => site.SiteMId
});

export const initialSiteState: SiteState = adapter.getInitialState({
    siteForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedSiteMId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function siteReducer(state = initialSiteState, action: SiteActions): SiteState {
    switch (action.type) {
        case SiteActionTypes.SitePageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedSiteMId: undefined
            };
        }
        case SiteActionTypes.SiteActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case SiteActionTypes.SiteOnServerCreated: return {
            ...state
        };
        case SiteActionTypes.SiteCreated: return adapter.addOne(action.payload.site, {
            ...state, lastCreatedSiteId: action.payload.site.SiteMId
        });
        case SiteActionTypes.SiteUpdated: return adapter.updateOne(action.payload.partialSite, state);
        case SiteActionTypes.OneSiteDeleted: return adapter.removeOne(action.payload.SiteMId, state);
        case SiteActionTypes.SitePageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case SiteActionTypes.SitePageLoaded: {
            return adapter.addMany(action.payload.sites, {
                ...initialSiteState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getSiteState = createFeatureSelector<SiteModel>('sites');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
