// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { MinesMasterActions, MinesMasterActionTypes } from '../_actions/minesMaster.actions';
// Models
import { MinesMasterModel } from '../_models/minesMaster.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface MinesMasterState extends EntityState<MinesMasterModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedMinesMId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<MinesMasterModel> = createEntityAdapter<MinesMasterModel>({
    selectId: minesMaster => minesMaster.MinesMId
});

export const initialMinesMasterState: MinesMasterState = adapter.getInitialState({
    minesMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedMinesMId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function minesMasterReducer(state = initialMinesMasterState, action: MinesMasterActions): MinesMasterState {
    switch (action.type) {
        case MinesMasterActionTypes.MinesMasterPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedMinesMId: undefined
            };
        }
        case MinesMasterActionTypes.MinesMasterActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case MinesMasterActionTypes.MinesMasterOnServerCreated: return {
            ...state
        };
        case MinesMasterActionTypes.MinesMasterCreated: return adapter.addOne(action.payload.minesMaster, {
            ...state, lastCreatedMinesMasterId: action.payload.minesMaster.MinesMId
        });
        case MinesMasterActionTypes.MinesMasterUpdated: return adapter.updateOne(action.payload.partialMinesMaster, state);
        case MinesMasterActionTypes.OneMinesMasterDeleted: return adapter.removeOne(action.payload.MinesMId, state);
        case MinesMasterActionTypes.MinesMasterPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case MinesMasterActionTypes.MinesMasterPageLoaded: {
            return adapter.addMany(action.payload.minesMasters, {
                ...initialMinesMasterState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getMinesMasterState = createFeatureSelector<MinesMasterModel>('minesMasters');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
