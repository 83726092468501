// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { CustomerActions, CustomerActionTypes } from '../_actions/customer.actions';
// Models
import { CustomerModel } from '../_models/customer.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface CustomerState extends EntityState<CustomerModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedCustId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CustomerModel> = createEntityAdapter<CustomerModel>({
    selectId: customer => customer.CustId
});

export const initialCustomerState: CustomerState = adapter.getInitialState({
    customerForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedCustId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function customerReducer(state = initialCustomerState, action: CustomerActions): CustomerState {
    switch (action.type) {
        case CustomerActionTypes.CustomerPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedCustId: undefined
            };
        }
        case CustomerActionTypes.CustomerActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case CustomerActionTypes.CustomerOnServerCreated: return {
            ...state
        };
        case CustomerActionTypes.CustomerCreated: return adapter.addOne(action.payload.customer, {
            ...state, lastCreatedCustomerId: action.payload.customer.CustId
        });
        case CustomerActionTypes.CustomerUpdated: return adapter.updateOne(action.payload.partialCustomer, state);
        case CustomerActionTypes.OneCustomerDeleted: return adapter.removeOne(action.payload.CustId, state);
        case CustomerActionTypes.CustomerPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case CustomerActionTypes.CustomerPageLoaded: {
            return adapter.addMany(action.payload.customers, {
                ...initialCustomerState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getCustomerState = createFeatureSelector<CustomerModel>('customers');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
