import { BaseModel } from '../../../_base/crud';

export class CompanyMasterModel extends BaseModel {
    CompanyMId: number;
    CompanyCode?: number;
    CompanyName: string;
    CompanyType: string;
    OwnerName: string;
    Products: string;
    Address1: string;
    Address2: string;
    PinCode: string;
    MobileNo: string;
    PhoneNo: string;
    FaxNo: string;
    EmailId?: string;
    Website?: string;
    TinNo?: string;
    TinDate?: Date;
    GSTNo: string;
    GSTDate?: Date;
    gstdate: string;
    CSTNo?: string;
    CSTDate?: Date;
    PhotoName?: string;
    ImageFile?: File;
    BankName?: string;
    AcccountNo?: string;
    IFSC?: string;
    Jurisdiction?: string;
    Plants?: string;
    AddminOffice?: string;
    Branches?: string;
    RegNo?: string;
    PanNo?: string;
    StockRegNo?: string;


    clear() {
        this.CompanyCode = undefined;
        this.CompanyName = '';
        this.CompanyType = '';
        this.OwnerName = '';
        this.Products = '';
        this.Address1 = '';
        this.Address2 = '';
        this.PinCode = '';
        this.MobileNo = '';
        this.PhoneNo = '';
        this.FaxNo = '';
        this.EmailId = '';
        this.Website = '';
        this.TinNo = '';
        this.TinDate = new Date();
        this.GSTNo = '';
        this.GSTDate = new Date();
        this.CSTNo = '';
        this.CSTDate = new Date();
        this.PhotoName = '';
        this.ImageFile = null;
        this.BankName = '';
        this.AcccountNo = '';
        this.IFSC = '';
        this.Jurisdiction = '';
        this.Plants = '';
        this.AddminOffice = '';
        this.Branches = '';
        this.RegNo = '';
        this.PanNo = '';
        this.StockRegNo = '';
    }
}