import { BaseModel } from '../../../_base/crud';

export class ContractorModel extends BaseModel {
	ContractorMId: number;
	CompanyMId: number;
	ContCode: number;
	ContCompanyName: string;
	ContName: string;
	ContAddress: string;
	ContPincode: string;
	ContMobileNo: string;
	ContPhoneNo: string;
	ContFaxNo: string;
	ContEmailId: string;
	ContWebsite: string;
	ContRefPerson1: string;
	ContRefPerson1Mo: string;
	ContRefPerson1EmailId: string;
	ContRefPerson2: string;
	ContRefPerson2Mo: string;
	ContRefPerson2EmailId: string;
	ContAddInfo1: string;
	ContAddInfo2: string;
	ContAddInfo3: number;
	ContAddInfo4: string;


	clear() {
		this.CompanyMId = undefined;
		this.ContCode = undefined;
		this.ContCompanyName = '';
		this.ContName = '';
		this.ContAddress = '';
		this.ContPincode = '';
		this.ContMobileNo = '';
		this.ContPhoneNo = '';
		this.ContFaxNo = '';
		this.ContEmailId = '';
		this.ContWebsite = '';
		this.ContRefPerson1 = '';
		this.ContRefPerson1Mo = '';
		this.ContRefPerson1EmailId = '';
		this.ContRefPerson2 = '';
		this.ContRefPerson2Mo = '';
		this.ContRefPerson2EmailId = '';
		this.ContAddInfo1 = '';
		this.ContAddInfo2 = '';
		this.ContAddInfo3 = undefined;
		this.ContAddInfo4 = '';
	}
}
