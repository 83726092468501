// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { DeliveryChallanModel } from '../_models/deliveryChallan.model';

export enum DeliveryChallanActionTypes {
    DeliveryChallanOnServerCreated = '[Edit DeliveryChallan Dialog] DeliveryChallan On Server Created',
    DeliveryChallanCreated = '[Edit DeliveryChallan Dialog] DeliveryChallan Created',
    DeliveryChallanUpdated = '[Edit DeliveryChallan Dialog] DeliveryChallan Updated',
    OneDeliveryChallanDeleted = '[DeliveryChallan List Page] One DeliveryChallan Deleted',
    DeliveryChallanPageRequested = '[DeliveryChallan List Page] DeliveryChallan Page Requested',
    DeliveryChallanPageLoaded = '[DeliveryChallan API] DeliveryChallan Page Loaded',
    DeliveryChallanPageCancelled = '[DeliveryChallan API] DeliveryChallan Page Cancelled',
    DeliveryChallanPageToggleLoading = '[DeliveryChallan] DeliveryChallan Page Toggle Loading',
    DeliveryChallanActionToggleLoading = '[DeliveryChallan] DeliveryChallan Action Toggle Loading'
}

export class DeliveryChallanOnServerCreated implements Action {
    readonly type = DeliveryChallanActionTypes.DeliveryChallanOnServerCreated;
    constructor(public payload: { deliveryChallan: DeliveryChallanModel }) { }
}

export class DeliveryChallanCreated implements Action {
    readonly type = DeliveryChallanActionTypes.DeliveryChallanCreated;
    constructor(public payload: { deliveryChallan: DeliveryChallanModel }) { }
}

export class DeliveryChallanUpdated implements Action {
    readonly type = DeliveryChallanActionTypes.DeliveryChallanUpdated;
    constructor(public payload: { 
        DCMId: number,
        partialDeliveryChallan: Update<DeliveryChallanModel>, // For State update
        deliveryChallan: DeliveryChallanModel 
    }) { }
}

export class OneDeliveryChallanDeleted implements Action {
    readonly type = DeliveryChallanActionTypes.OneDeliveryChallanDeleted;
    constructor(public payload: { DCMId: number }) { }
}

export class DeliveryChallanPageRequested implements Action {
    readonly type = DeliveryChallanActionTypes.DeliveryChallanPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class DeliveryChallanPageLoaded implements Action {
    readonly type = DeliveryChallanActionTypes.DeliveryChallanPageLoaded;
    constructor(public payload: { deliveryChallans: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class DeliveryChallanPageCancelled implements Action {
    readonly type = DeliveryChallanActionTypes.DeliveryChallanPageCancelled;
}

export class DeliveryChallanPageToggleLoading implements Action {
    readonly type = DeliveryChallanActionTypes.DeliveryChallanPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class DeliveryChallanActionToggleLoading implements Action {
    readonly type = DeliveryChallanActionTypes.DeliveryChallanActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type DeliveryChallanActions = DeliveryChallanOnServerCreated
    | DeliveryChallanCreated
    | DeliveryChallanUpdated
    | OneDeliveryChallanDeleted
    | DeliveryChallanPageRequested
    | DeliveryChallanPageLoaded
    | DeliveryChallanPageCancelled
    | DeliveryChallanPageToggleLoading
    | DeliveryChallanActionToggleLoading;
