// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { UserMasterActions, UserMasterActionTypes } from '../_actions/userMaster.actions';
// Models
import { UserMasterModel } from '../_models/userMaster.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface UserMasterState extends EntityState<UserMasterModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedUserId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<UserMasterModel> = createEntityAdapter<UserMasterModel>({
    selectId: userMaster => userMaster.UserId
});

export const initialUserMasterState: UserMasterState = adapter.getInitialState({
    userMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedUserId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function userMasterReducer(state = initialUserMasterState, action: UserMasterActions): UserMasterState {
    switch (action.type) {
        case UserMasterActionTypes.UserMasterPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedUserId: undefined
            };
        }
        case UserMasterActionTypes.UserMasterActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case UserMasterActionTypes.UserMasterOnServerCreated: return {
            ...state
        };
        case UserMasterActionTypes.UserMasterCreated: return adapter.addOne(action.payload.userMaster, {
            ...state, lastCreatedUserMasterId: action.payload.userMaster.UserId
        });
        case UserMasterActionTypes.UserMasterUpdated: return adapter.updateOne(action.payload.partialUserMaster, state);
        case UserMasterActionTypes.OneUserMasterDeleted: return adapter.removeOne(action.payload.UserId, state);
        case UserMasterActionTypes.UserMasterPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case UserMasterActionTypes.UserMasterPageLoaded: {
            return adapter.addMany(action.payload.userMasters, {
                ...initialUserMasterState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getUserMasterState = createFeatureSelector<UserMasterModel>('userMasters');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
