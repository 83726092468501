import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

//Models
import { IProductModel } from '../core/masters/product-master';
import { ContractorModel } from '../core/masters/contractor';
import { CustomerModel } from '../core/masters/customer';
import { ContractorRateModel } from '../core/masters/contractorrate';
import { CustomerRateModel } from '../core/masters/customerrate';
import { SiteModel } from '../core/masters/site';
import { MinesMasterModel } from '../core/masters/minesmaster';
import { TransporterModel } from '../core/masters/transporter';
import { VehicleModel } from '../core/masters/vehicle';
//TransactionModels
import { DeliveryChallanModel } from '../core/transactions/deliverychallan';
import { InvoiceModel } from '../core/transactions/invoicetransaction';
import { WarehouseModel } from '../core/transactions/warehousetransaction';

@Injectable({
  providedIn: 'root'
})
export class ShareddataserviceService implements OnInit {

  loggedCompany: any;
  product: IProductModel[];
  contractor: ContractorModel[];
  customer: CustomerModel[];
  contractorRate: ContractorRateModel[];
  customerRate: CustomerRateModel[]
  site: SiteModel[];
  mine: MinesMasterModel[];
  transporter: TransporterModel[];
  vehicle: VehicleModel[];
  deliveryChallan: DeliveryChallanModel[];
  invoice: InvoiceModel[];
  warehouse: WarehouseModel[];

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  private gridDataSource = new BehaviorSubject("default data");
  currentGridData = this.gridDataSource.asObservable();

  private loginCompanyData = new BehaviorSubject('');
  companydata = this.loginCompanyData.asObservable();

  private products = new BehaviorSubject(this.product);
  productList = this.products.asObservable();

  private contractors = new BehaviorSubject(this.contractor);
  contractorList = this.contractors.asObservable();

  private customers = new BehaviorSubject(this.customer);
  customerList = this.customers.asObservable();

  private contractorrate = new BehaviorSubject(this.contractorRate);
  contractorRateList = this.contractorrate.asObservable();

  private customerrate = new BehaviorSubject(this.customerRate);
  customerRateList = this.customerrate.asObservable();

  private transporters = new BehaviorSubject(this.transporter);
  transporterList = this.transporters.asObservable();

  private vehicles = new BehaviorSubject(this.vehicle);
  vehicleList = this.vehicles.asObservable();

  private mines = new BehaviorSubject(this.mine);
  mineList = this.mines.asObservable();

  private sites = new BehaviorSubject(this.site);
  siteList = this.sites.asObservable();

  private deliverychallan = new BehaviorSubject(this.deliveryChallan);
  deliveryChallanList = this.deliverychallan.asObservable();

  private invoices = new BehaviorSubject(this.invoice);
  invoiceList = this.invoices.asObservable();

  private warehouses = new BehaviorSubject(this.warehouse);
  warehousesList = this.warehouses.asObservable();



  constructor() { }

  ngOnInit(): void {
    this.loggedCompany = JSON.parse(sessionStorage.getItem('logedCompany'));
    this.onChangeCompanyData(this.loggedCompany);
  }

  changeParameter(message: any) {
    this.messageSource.next(message)
  }

  changeMessage(message: any) {
    this.gridDataSource.next(message)
  }

  onChangeCompanyData(company: any) {
    this.loginCompanyData.next(company)
  }

  updateProductList(product: any) {
    this.products.next(product)
  }

  updateContractorList(contractor: any) {
    this.contractors.next(contractor)
  }

  updateCustomerList(customer: any) {
    this.customers.next(customer)
  }

  updateContractorRateList(contractorRate: any) {
    this.contractorrate.next(contractorRate)
  }

  updateCustomerRateList(customerRate: any) {
    this.customerrate.next(customerRate)
  }

  updateTransporterList(transporter: any) {
    this.transporters.next(transporter)
  }

  updateVehicleList(vehicle: any) {
    this.vehicles.next(vehicle)
  }

  updatemineList(mine: any) {
    this.mines.next(mine)
  }

  updateSiteList(site: any) {
    this.sites.next(site)
  }

  updateDeliveryChallanList(deliveryChallan: any) {
    this.deliverychallan.next(deliveryChallan)
  }

  updateInvoiceList(invoice: any) {
    this.invoices.next(invoice)
  }

  updateWarehouseList(warehouse: any) {
    this.warehouses.next(warehouse)
  }

}
