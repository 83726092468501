// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { ProductsState } from '../_reducers/product.reducers';
import { IProductModel } from '../models/product';

export const selectProductsState = createFeatureSelector<ProductsState>('products');

export const selectProductById = (ProdCatMId: number) => createSelector(
    selectProductsState,
    productsState => productsState.entities[ProdCatMId]
);

export const selectProductsPageLoading = createSelector(
    selectProductsState,
    productsState => productsState.listLoading
);

export const selectProductsActionLoading = createSelector(
    selectProductsState,
    productsState => productsState.actionsloading
);

export const selectLastCreatedProductCatMId = createSelector(
    selectProductsState,
    productsState => productsState.lastCreatedProdCatMId
);

export const selectProductsShowInitWaitingMessage = createSelector(
    selectProductsState,
    productsState => productsState.showInitWaitingMessage
);

export const selectProductsInStore = createSelector(
    selectProductsState,
    productsState => {
        const items: IProductModel[] = [];
        each(productsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: IProductModel[] = httpExtension.sortArray(items, productsState.lastQuery.sortField, productsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, productsState.totalCount);
    }
);