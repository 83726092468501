// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { ContractorRateActions, ContractorRateActionTypes } from '../_actions/contractorRate.actions';
// Models
import { ContractorRateModel } from '../_models/contractorRate.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface ContractorRateState extends EntityState<ContractorRateModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedContractorRateId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ContractorRateModel> = createEntityAdapter<ContractorRateModel>({
    selectId: contractorRate => contractorRate.ContractorRateId
});

export const initialContractorRateState: ContractorRateState = adapter.getInitialState({
    contractorRateForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedContractorRateId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function contractorRateReducer(state = initialContractorRateState, action: ContractorRateActions): ContractorRateState {
    switch (action.type) {
        case ContractorRateActionTypes.ContractorRatePageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedContractorRateId: undefined
            };
        }
        case ContractorRateActionTypes.ContractorRateActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case ContractorRateActionTypes.ContractorRateOnServerCreated: return {
            ...state
        };
        case ContractorRateActionTypes.ContractorRateCreated: return adapter.addOne(action.payload.contractorRate, {
            ...state, lastCreatedContractorRateId: action.payload.contractorRate.ContractorRateId
        });
        case ContractorRateActionTypes.ContractorRateUpdated: return adapter.updateOne(action.payload.partialContractorRate, state);
        case ContractorRateActionTypes.ContractorRateStatusUpdated: {
            const _partialUserReviews: Update<ContractorRateModel>[] = [];
            // tslint:disable-next-line:prefer-const
            for (let i = 0; i < action.payload.contractorRate.length; i++) {
                _partialUserReviews.push({
				    id: action.payload.contractorRate[i].ContractorRateId,
				    changes: {
                        ProductRate: action.payload.ProductRate
                    }
			    });
            }
            return adapter.updateMany(_partialUserReviews, state);
        }
        case ContractorRateActionTypes.OneContractorRateDeleted: return adapter.removeOne(action.payload.ContractorRateId, state);
        case ContractorRateActionTypes.ContractorRatePageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case ContractorRateActionTypes.ContractorRatePageLoaded: {
            return adapter.addMany(action.payload.contractorRates, {
                ...initialContractorRateState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getContractorRateState = createFeatureSelector<ContractorRateModel>('contractorRates');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
