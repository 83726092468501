// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { IProductModel } from '../models/product';

export enum ProductActionTypes {
    ProductOnServerCreated = '[Edit Product Dialog] Product On Server Created',
    ProductCreated = '[Edit Product Dialog] Product Created',
    ProductUpdated = '[Edit Product Dialog] Product Updated',
    ProductsStatusUpdated = '[Product List Page] Products Status Updated',
    OneProductDeleted = '[Products List Page] One Product Deleted',
    ManyProductsDeleted = '[Products List Page] Many Product Deleted',
    ProductsPageRequested = '[Products List Page] Products Page Requested',
    ProductsPageLoaded = '[Products API] Products Page Loaded',
    ProductsPageCancelled = '[Products API] Products Page Cancelled',
    ProductsPageToggleLoading = '[Products] Products Page Toggle Loading',
    ProductActionToggleLoading = '[Products] Products Action Toggle Loading'
}

export class ProductOnServerCreated implements Action {
    readonly type = ProductActionTypes.ProductOnServerCreated;
    constructor(public payload: { product: IProductModel }) { }
}

export class ProductCreated implements Action {
    readonly type = ProductActionTypes.ProductCreated;
    constructor(public payload: { product: IProductModel }) { }
}

export class ProductUpdated implements Action {
    readonly type = ProductActionTypes.ProductUpdated;
    constructor(public payload: {
        ProdCatMId: number,
        partialProduct: Update<IProductModel>, // For State update
        product: IProductModel // For Server update (through service)
    }) { }
}

export class OneProductDeleted implements Action {
    readonly type = ProductActionTypes.OneProductDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyProductsDeleted implements Action {
    readonly type = ProductActionTypes.ManyProductsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class ProductsPageRequested implements Action {
    readonly type = ProductActionTypes.ProductsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class ProductsPageLoaded implements Action {
    readonly type = ProductActionTypes.ProductsPageLoaded;
    constructor(public payload: { products: IProductModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class ProductsPageCancelled implements Action {
    readonly type = ProductActionTypes.ProductsPageCancelled;
}

export class ProductsPageToggleLoading implements Action {
    readonly type = ProductActionTypes.ProductsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ProductActionToggleLoading implements Action {
    readonly type = ProductActionTypes.ProductActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type ProductActions = ProductOnServerCreated
| ProductCreated
| ProductUpdated
| OneProductDeleted
| ManyProductsDeleted
| ProductsPageRequested
| ProductsPageLoaded
| ProductsPageCancelled
| ProductsPageToggleLoading
| ProductActionToggleLoading;