// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../_base/crud';

import { Global } from '../../../../shared/global';
// Models
import { InvoiceModel } from '../_models/invoice.model';
import { mergeMap } from 'rxjs/operators';
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';

const API_CUSTOMERS_URL = Global.BASE_INVOICE_ENDPOINT;
const API_URL = Global.BASE_INVOICE_BY_COMPANYMID;

@Injectable()
export class InvoiceService {
	loggedCompanyMId: number;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService,
		private sharedService: ShareddataserviceService) { }

	getLoggedCompanyMId() {
		this.sharedService.companydata.subscribe(data => this.loggedCompanyMId = data[0]["CompanyMId"]);
	}

	// CREATE =>  POST: add a new invoice to the server
	createInvoice(invoice: InvoiceModel): Observable<InvoiceModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<InvoiceModel>(API_CUSTOMERS_URL, invoice, { headers: httpHeaders});
	}

	uploadImage(imageFile: File, imageName:any): Observable<any> {
		let formData = new FormData();
		formData.append('ImageFile', imageFile, imageFile.name);
		formData.append('imageName', imageName);

		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'multipart/form-data');

		const url = "Global.BASE_UPLOAD_CATEGORY_IMAGE_ENDPOINT";
		// Note: Add headers if needed (tokens/bearer)
		return this.http.post<any>(url, formData, { headers: httpHeaders });
	}

	// READ
	getAllInvoices(): Observable<InvoiceModel[]> {
		this.getLoggedCompanyMId();
		const URL = API_URL + this.loggedCompanyMId;
		return this.http.get<InvoiceModel[]>(URL);
	}

	getInvoiceById(InvoiceId: number): Observable<InvoiceModel> {
		return this.http.get<InvoiceModel>(API_CUSTOMERS_URL + `/${InvoiceId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findInvoices(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		this.getLoggedCompanyMId();
		const URL = API_URL + this.loggedCompanyMId;
		return this.http.get<QueryResultsModel[]>(URL).pipe(
            mergeMap(res => {
                const result = this.httpUtils.baseFilter(res, queryParams);
                return of(result);
            })
        );
	}

	// UPDATE => PUT: update the invoice on the server
	updateInvoice(InvoiceId: number, invoice: InvoiceModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_CUSTOMERS_URL + InvoiceId, invoice, { headers: httpHeader });
	}

	// DELETE => delete the invoice from the server
	deleteInvoice(InvoiceId: number): Observable<InvoiceModel> {
		const url = `${API_CUSTOMERS_URL}${InvoiceId}`;
		return this.http.delete<InvoiceModel>(url);
	}
}
