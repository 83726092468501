import { QueryParamsModel } from '../../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../../_base/crud';
// Services
import { ContractorRateService } from '../_services/contractorRate.service';
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';
// State
import { AppState } from '../../../../core/reducers';
// Actions
import {
    ContractorRateActionTypes,
    ContractorRatePageRequested,
    ContractorRatePageLoaded,
    OneContractorRateDeleted,
    ContractorRateActionToggleLoading,
    ContractorRatePageToggleLoading,
    ContractorRateUpdated,
    ContractorRateStatusUpdated,
    ContractorRateCreated,
    ContractorRateOnServerCreated
} from '../_actions/contractorRate.actions';
import { of } from 'rxjs';

@Injectable()
export class ContractorRateEffects {
    showPageLoadingDistpatcher = new ContractorRatePageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new ContractorRateActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new ContractorRateActionToggleLoading({ isLoading: false });

    @Effect()
    loadContractorRatePage$ = this.actions$.pipe(
        ofType<ContractorRatePageRequested>(ContractorRateActionTypes.ContractorRatePageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.contractorRateService.findContractorRates(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];

            this.sharedService.updateContractorRateList(result.items);
            //console.log(result.items);
            const pageLoadedDispatch = new ContractorRatePageLoaded({
                contractorRates: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteContractorRate$ = this.actions$
        .pipe(
            ofType<OneContractorRateDeleted>(ContractorRateActionTypes.OneContractorRateDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.contractorRateService.deleteContractorRate(payload.ContractorRateId);
            }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateContractorRate$ = this.actions$
        .pipe(
            ofType<ContractorRateUpdated>(ContractorRateActionTypes.ContractorRateUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.contractorRateService.updateContractorRate(payload.contractorRate.ContractorRateId, payload.contractorRate);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateUserReviewsStatus$ = this.actions$
        .pipe(
            ofType<ContractorRateStatusUpdated>(ContractorRateActionTypes.ContractorRateStatusUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.contractorRateService.updateStatusForContractorRate(payload.contractorRate, payload.ProductRate);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createContractorRate$ = this.actions$
        .pipe(
            ofType<ContractorRateOnServerCreated>(ContractorRateActionTypes.ContractorRateOnServerCreated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.contractorRateService.createContractorRate(payload.contractorRate).pipe(
                    tap(res => {
                        //console.log(res);
                        //const resValue = Object.keys(res).map(key => res[key]);
                        //console.log(resValue[0].value);
                        //this.store.dispatch(new ContractorRateCreated({ contractorRate: res }));
                        if (res.ContractorRateId == 0) {
                            this.store.dispatch(new ContractorRateCreated({ contractorRate: res }));
                        } else {
                            console.log('There is somthing wrong');
                            //this.store.dispatch(new ContractorRateErrorMessage({ _errorMessage: resValue[0].value }));
                        }

                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private contractorRateService: ContractorRateService,
        private sharedService: ShareddataserviceService, private store: Store<AppState>) { }
}
