// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { VehicleActions, VehicleActionTypes } from '../_actions/vehicle.actions';
// Models
import { VehicleModel } from '../_models/vehicle.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface VehicleState extends EntityState<VehicleModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedVehicleMId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<VehicleModel> = createEntityAdapter<VehicleModel>({
    selectId: vehicle => vehicle.VehicleMId
});

export const initialVehicleState: VehicleState = adapter.getInitialState({
    vehicleForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedVehicleMId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function vehicleReducer(state = initialVehicleState, action: VehicleActions): VehicleState {
    switch (action.type) {
        case VehicleActionTypes.VehiclePageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedVehicleMId: undefined
            };
        }
        case VehicleActionTypes.VehicleActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case VehicleActionTypes.VehicleOnServerCreated: return {
            ...state
        };
        case VehicleActionTypes.VehicleCreated: return adapter.addOne(action.payload.vehicle, {
            ...state, lastCreatedVehicleId: action.payload.vehicle.VehicleMId
        });
        case VehicleActionTypes.VehicleUpdated: return adapter.updateOne(action.payload.partialVehicle, state);
        case VehicleActionTypes.OneVehicleDeleted: return adapter.removeOne(action.payload.VehicleMId, state);
        case VehicleActionTypes.VehiclePageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case VehicleActionTypes.VehiclePageLoaded: {
            return adapter.addMany(action.payload.vehicles, {
                ...initialVehicleState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getVehicleState = createFeatureSelector<VehicleModel>('vehicles');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
