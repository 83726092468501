// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { SiteModel } from '../_models/site.model';

export enum SiteActionTypes {
    SiteOnServerCreated = '[Edit Site Dialog] Site On Server Created',
    SiteCreated = '[Edit Site Dialog] Site Created',
    SiteUpdated = '[Edit Site Dialog] Site Updated',
    OneSiteDeleted = '[Site List Page] One Site Deleted',
    SitePageRequested = '[Site List Page] Site Page Requested',
    SitePageLoaded = '[Site API] Site Page Loaded',
    SitePageCancelled = '[Site API] Site Page Cancelled',
    SitePageToggleLoading = '[Site] Site Page Toggle Loading',
    SiteActionToggleLoading = '[Site] Site Action Toggle Loading'
}

export class SiteOnServerCreated implements Action {
    readonly type = SiteActionTypes.SiteOnServerCreated;
    constructor(public payload: { site: SiteModel }) { }
}

export class SiteCreated implements Action {
    readonly type = SiteActionTypes.SiteCreated;
    constructor(public payload: { site: SiteModel }) { }
}

export class SiteUpdated implements Action {
    readonly type = SiteActionTypes.SiteUpdated;
    constructor(public payload: { 
        SiteMId: number,
        partialSite: Update<SiteModel>, // For State update
        site: SiteModel 
    }) { }
}

export class OneSiteDeleted implements Action {
    readonly type = SiteActionTypes.OneSiteDeleted;
    constructor(public payload: { SiteMId: number }) { }
}

export class SitePageRequested implements Action {
    readonly type = SiteActionTypes.SitePageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class SitePageLoaded implements Action {
    readonly type = SiteActionTypes.SitePageLoaded;
    constructor(public payload: { sites: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class SitePageCancelled implements Action {
    readonly type = SiteActionTypes.SitePageCancelled;
}

export class SitePageToggleLoading implements Action {
    readonly type = SiteActionTypes.SitePageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class SiteActionToggleLoading implements Action {
    readonly type = SiteActionTypes.SiteActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type SiteActions = SiteOnServerCreated
    | SiteCreated
    | SiteUpdated
    | OneSiteDeleted
    | SitePageRequested
    | SitePageLoaded
    | SitePageCancelled
    | SitePageToggleLoading
    | SiteActionToggleLoading;
