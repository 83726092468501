// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { ContractorRateState } from '../_reducers/contractorRate.reducers';
import { ContractorRateModel } from '../_models/contractorRate.model';

export const selectContractorRateState = createFeatureSelector<ContractorRateState>('contractorRates');

export const selectContractorRateById = (ContractorRateId: number) => createSelector(
    selectContractorRateState,
    contractorRateState => contractorRateState.entities[ContractorRateId]
);

export const selectContractorRatePageLoading = createSelector(
    selectContractorRateState,
    contractorRateState => contractorRateState.listLoading
);

export const selectContractorRateActionLoading = createSelector(
    selectContractorRateState,
    contractorRateState => contractorRateState.actionsloading
);

export const selectLastCreatedContractorRateId = createSelector(
    selectContractorRateState,
    contractorRateState => contractorRateState.lastCreatedContractorRateId
);

export const selectContractorRateShowInitWaitingMessage = createSelector(
    selectContractorRateState,
    contractorRateState => contractorRateState.showInitWaitingMessage
);

export const selectContractorRateInStore = createSelector(
    selectContractorRateState,
    contractorRateState => {
        const items: ContractorRateModel[] = [];
        each(contractorRateState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ContractorRateModel[] = httpExtension.sortArray(items, contractorRateState.lastQuery.sortField, contractorRateState.lastQuery.sortOrder);
        return new QueryResultsModel(result, contractorRateState.totalCount, '');
    }
);
