import { mergeMap, tap } from 'rxjs/operators';
// RxJS
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../../_base/crud';
// State
import { AppState } from '../../../reducers';
import { selectMinesMasterInStore, selectMinesMasterPageLoading, selectMinesMasterShowInitWaitingMessage } from '../_selectors/minesMaster.selectors';

export class MinesMasterDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();

		this.loading$ = this.store.pipe(
			select(selectMinesMasterPageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectMinesMasterShowInitWaitingMessage)
		);

		this.store.pipe(
			select(selectMinesMasterInStore),
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}
