import { BaseModel } from '../../../_base/crud';

export class DeliveryChallanModel extends BaseModel {
	DCMId: number;
	CompanyMId?: number;
	DCNo?: number;
	DCDate?: Date;
	POId: string;
	PODate?: Date;
	podate?:string;
	MinesMId?: number;
	SiteMId?: number;
	ContractorMId?: number;
	CustId?: number;
	Destination: string;
	ProdMId?: number;
	RoyaltyWgt?: number;
	LoadingBy: string;
	TransportMId?: number;
	VehicleNo: string;
	RdBtnMode?: number;
	RDCNo: string;
	ERoyaltyNo: string;
	Freight?: number;
	Remark: string;
	CustomerVNo?: number;
	ContVNo?: number;
	FreightMode?: number;
	StrFreight: string;
	CompanyWgt?: number;
	LoadContract?: number;
	NoofBags?: number;
	PerUnit?: number;
	ByBagRate?: Boolean;
	DriverName: string;
	DriverNo: string;
	DriverAdvance: number;


	clear() {
		this.CompanyMId = undefined;
		this.DCNo = undefined;
		this.DCDate = new Date;
		this.POId= '';
		this.PODate = new Date;
		this.MinesMId = undefined;
		this.SiteMId = undefined;
		this.ContractorMId = undefined;
		this.CustId = undefined;
		this.Destination= '';
		this.ProdMId = undefined;
		this.RoyaltyWgt = 0.00;
		this.LoadingBy= '';
		this.TransportMId = undefined;
		this.VehicleNo= '';
		this.RdBtnMode = 0;
		this.RDCNo= '';
		this.ERoyaltyNo= '';
		this.Freight = undefined;
		this.Remark= '';
		this.CustomerVNo = 0;//driver advance
		this.ContVNo = 0;
		this.FreightMode = 0;
		this.StrFreight= '';
		this.CompanyWgt = 0.00;
		this.LoadContract = undefined;
		this.NoofBags = undefined;
		this.PerUnit = undefined;
		this.ByBagRate = undefined;
		this.DriverName= '';
		this.DriverNo = '';
		this.DriverAdvance = 0;
	}
}
