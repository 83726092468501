// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { MinesMasterModel } from '../_models/minesMaster.model';

export enum MinesMasterActionTypes {
    MinesMasterOnServerCreated = '[Edit MinesMaster Dialog] MinesMaster On Server Created',
    MinesMasterCreated = '[Edit MinesMaster Dialog] MinesMaster Created',
    MinesMasterUpdated = '[Edit MinesMaster Dialog] MinesMaster Updated',
    OneMinesMasterDeleted = '[MinesMaster List Page] One MinesMaster Deleted',
    MinesMasterPageRequested = '[MinesMaster List Page] MinesMaster Page Requested',
    MinesMasterPageLoaded = '[MinesMaster API] MinesMaster Page Loaded',
    MinesMasterPageCancelled = '[MinesMaster API] MinesMaster Page Cancelled',
    MinesMasterPageToggleLoading = '[MinesMaster] MinesMaster Page Toggle Loading',
    MinesMasterActionToggleLoading = '[MinesMaster] MinesMaster Action Toggle Loading'
}

export class MinesMasterOnServerCreated implements Action {
    readonly type = MinesMasterActionTypes.MinesMasterOnServerCreated;
    constructor(public payload: { minesMaster: MinesMasterModel }) { }
}

export class MinesMasterCreated implements Action {
    readonly type = MinesMasterActionTypes.MinesMasterCreated;
    constructor(public payload: { minesMaster: MinesMasterModel }) { }
}

export class MinesMasterUpdated implements Action {
    readonly type = MinesMasterActionTypes.MinesMasterUpdated;
    constructor(public payload: { 
        MinesMId: number,
        partialMinesMaster: Update<MinesMasterModel>, // For State update
        minesMaster: MinesMasterModel 
    }) { }
}

export class OneMinesMasterDeleted implements Action {
    readonly type = MinesMasterActionTypes.OneMinesMasterDeleted;
    constructor(public payload: { MinesMId: number }) { }
}

export class MinesMasterPageRequested implements Action {
    readonly type = MinesMasterActionTypes.MinesMasterPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class MinesMasterPageLoaded implements Action {
    readonly type = MinesMasterActionTypes.MinesMasterPageLoaded;
    constructor(public payload: { minesMasters: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class MinesMasterPageCancelled implements Action {
    readonly type = MinesMasterActionTypes.MinesMasterPageCancelled;
}

export class MinesMasterPageToggleLoading implements Action {
    readonly type = MinesMasterActionTypes.MinesMasterPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class MinesMasterActionToggleLoading implements Action {
    readonly type = MinesMasterActionTypes.MinesMasterActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type MinesMasterActions = MinesMasterOnServerCreated
    | MinesMasterCreated
    | MinesMasterUpdated
    | OneMinesMasterDeleted
    | MinesMasterPageRequested
    | MinesMasterPageLoaded
    | MinesMasterPageCancelled
    | MinesMasterPageToggleLoading
    | MinesMasterActionToggleLoading;
