import { BaseModel } from '../../../_base/crud';

export class WarehouseModel extends BaseModel {
	WarehouseId: number;
	WarehouseCode?: number;
	CompanyMId?: number;
	DcNo?: number;
	DcDate: Date;
	MinesMId?: number;
	SiteMId?: number;
	VehicleMId?: number;
	TransportMId?: number;
	RoyaltyNo: string;
	ProdCatMId?: number;
	RoyaltyWgt?: number;
	ActualWgt?: number;
	VhFare?: number;


	clear() {
		this.WarehouseCode = undefined;
		this.CompanyMId = undefined;
		this.DcNo = undefined;
		this.DcDate = new Date;
		this.MinesMId = undefined;
		this.SiteMId = undefined;
		this.VehicleMId = undefined;
		this.TransportMId = undefined;
		this.RoyaltyNo = '';
		this.ProdCatMId = undefined;
		this.RoyaltyWgt = 0.00;
		this.ActualWgt = 0.00;
		this.VhFare = undefined;
	}
}
