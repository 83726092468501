import { QueryParamsModel } from '../../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../../_base/crud';
// Services
import { UserMasterService } from '../_services/userMaster.service';
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';
// State
import { AppState } from '../../../../core/reducers';
// Actions
import {
    UserMasterActionTypes,
    UserMasterPageRequested,
    UserMasterPageLoaded,
    OneUserMasterDeleted,
    UserMasterActionToggleLoading,
    UserMasterPageToggleLoading,
    UserMasterUpdated,
    UserMasterCreated,
    UserMasterOnServerCreated
} from '../_actions/userMaster.actions';
import { of } from 'rxjs';

@Injectable()
export class UserMasterEffects {
    showPageLoadingDistpatcher = new UserMasterPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new UserMasterActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new UserMasterActionToggleLoading({ isLoading: false });

    @Effect()
    loadUserMasterPage$ = this.actions$.pipe(
        ofType<UserMasterPageRequested>(UserMasterActionTypes.UserMasterPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.userMasterService.findUserMasters(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];

            this.sharedService.updatemineList(result.items);
            //console.log(result.items);
            const pageLoadedDispatch = new UserMasterPageLoaded({
                userMasters: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteUserMaster$ = this.actions$
        .pipe(
            ofType<OneUserMasterDeleted>(UserMasterActionTypes.OneUserMasterDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.userMasterService.deleteUserMaster(payload.UserId);
            }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateUserMaster$ = this.actions$
        .pipe(
            ofType<UserMasterUpdated>(UserMasterActionTypes.UserMasterUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.userMasterService.updateUserMaster(payload.userMaster.UserId, payload.userMaster);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createUserMaster$ = this.actions$
        .pipe(
            ofType<UserMasterOnServerCreated>(UserMasterActionTypes.UserMasterOnServerCreated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.userMasterService.createUserMaster(payload.userMaster).pipe(
                    tap(res => {
                        //console.log(res);
                        //const resValue = Object.keys(res).map(key => res[key]);
                        //console.log(resValue[0].value);
                        //this.store.dispatch(new UserMasterCreated({ userMaster: res }));
                        if (res.UserId == 0) {
                            this.store.dispatch(new UserMasterCreated({ userMaster: res }));
                        } else {
                            console.log('There is somthing wrong');
                            //this.store.dispatch(new UserMasterErrorMessage({ _errorMessage: resValue[0].value }));
                        }

                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private userMasterService: UserMasterService,
        private sharedService: ShareddataserviceService, private store: Store<AppState>) { }
}
