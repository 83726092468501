import { BaseModel } from '../../../_base/crud';

export class CustomerModel extends BaseModel {
	CustId: number;
	CompanyMId: number;
	CustCode: number;
	CustName: string;
	CustAddress: string;
	CustPinCode: string;
	CustMobileNo: string;
	CustPhoneNo: string;
	CustFaxNo: string;
	CustEmailId: string;
	CustWebSite: string;
	CustRefPerson1: string;
	CustRefPerson1Mo: string;
	CustRefPerson1Email: string;
	CustRefPerson2: string;
	CustRefPerson2Mo: string;
	CustRefPerson2Email: string;
	CustGSTTinNo: string;
	CustGSTDate: string;
	CustCSTTinNo: string;
	CustCSTDate: string;
	CustAddInfo1: string;
	CustAddInfo2: string;
	CustAddInfo3: number;
	CustAddInfo4: number;
	CompanyName: string;
	DueDays: string;
	DueDate: Date;


	clear() {
		this.CompanyMId = undefined;
		this.CustCode = undefined;
		this.CustName = '';
		this.CustAddress = '';
		this.CustPinCode = '';
		this.CustMobileNo = '';
		this.CustPhoneNo = '';
		this.CustFaxNo = '';
		this.CustEmailId = '';
		this.CustWebSite = '';
		this.CustRefPerson1 = '';
		this.CustRefPerson1Mo = '';
		this.CustRefPerson1Email = '';
		this.CustRefPerson2 = '';
		this.CustRefPerson2Mo = '';
		this.CustRefPerson2Email = '';
		this.CustGSTTinNo = '';
		this.CustGSTDate = '';
		this.CustCSTTinNo = '';
		this.CustCSTDate = '';
		this.CustAddInfo1 = '';
		this.CustAddInfo2 = '';
		this.CustAddInfo3 = undefined;
		this.CustAddInfo4 = undefined;
		this.CompanyName = '';
		this.DueDays = '';
		this.DueDate = undefined;
	}
}
