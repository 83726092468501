import { BaseModel } from '../../../_base/crud';

export class VehicleModel extends BaseModel {
	VehicleMId: number;
	CompanyMId: number;
	VehicleCode: number;
	TransportMId: number;
	VehicleType: number;
	VehicleName: string;
	VehicleNo: string;
	VehicleFare: string;
	VehicleAddInfo1: string;
	VehicleAddInfo2: string;
	VehicleAddInfo3: string;
	VehicleAddInfo4: string;


	clear() {
		this.VehicleMId= undefined;
		this.CompanyMId= undefined;
		this.VehicleCode= undefined;
		this.TransportMId= undefined;
		this.VehicleType= undefined;
		this.VehicleName= undefined;
		this.VehicleNo= undefined;
		this.VehicleFare= undefined;
		this.VehicleAddInfo1= undefined;
		this.VehicleAddInfo2= undefined;
		this.VehicleAddInfo3= undefined;
		this.VehicleAddInfo4= undefined;

	}
}
