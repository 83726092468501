// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject, throwError } from 'rxjs';
import { finalize, takeUntil, tap, catchError } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyMasterModel, CompanyMasterService } from '../../../../core/masters/companymaster';
//Services
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';
import { ProductsService } from '../../../../core/masters/product-master';
import { ContractorService } from '../../../../core/masters/contractor';
import { CustomerService } from '../../../../core/masters/customer';
import { ContractorRateService } from '../../../../core/masters/contractorrate';
import { CustomerRateService } from '../../../../core/masters/customerrate';
import { SiteService } from '../../../../core/masters/site';
import { MinesMasterService } from '../../../../core/masters/minesmaster';
import { TransporterService } from '../../../../core/masters/transporter';
import { VehicleService } from '../../../../core/masters/vehicle';
import { DeliveryChallanService } from '../../../../core/transactions/deliverychallan';
import { InvoiceService } from '../../../../core/transactions/invoicetransaction';
import { WarehouseService } from '../../../../core/transactions/warehousetransaction';

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	CompanyMId: undefined,
	EMAIL: '',
	USERNAME: '',
	PASSWORD: '',
};

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	comloading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	companies: CompanyMasterModel[];
	_selectedCompany: CompanyMasterModel[];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private companyService: CompanyMasterService,
		private sharedService: ShareddataserviceService,
		private prouctService: ProductsService,
		private contractorService: ContractorService,
		private customerService: CustomerService,
		private contractorRateService: ContractorRateService,
		private customerRateService: CustomerRateService,
		private siteService: SiteService,
		private mineService: MinesMasterService,
		private transporterService: TransporterService,
		private vehicleService: VehicleService,
		private dcService: DeliveryChallanService,
		private invoiceService: InvoiceService,
		private warehouseService: WarehouseService
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const FYear = sessionStorage.getItem('FinancialYear');
		if (FYear === null) {
			this.router.navigateByUrl('/auth/financial-year');
		}
		this.initLoginForm();
		this.companyList();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
		//this.comloading = false;
	}

	getApiData() {
		this.prouctService.getAllProducts().subscribe(res => {
			this.sharedService.updateProductList(res);
		});

		this.contractorService.getAllContractors().subscribe(res => {
			this.sharedService.updateContractorList(res);
		});

		this.customerService.getAllCustomers().subscribe(res => {
			this.sharedService.updateCustomerList(res);
		});

		this.contractorRateService.getAllContractorRates().subscribe(res => {
			this.sharedService.updateContractorRateList(res);
		});

		this.customerRateService.getAllCustomerRates().subscribe(res => {
			this.sharedService.updateCustomerRateList(res);
		});

		this.siteService.getAllSites().subscribe(res => {
			this.sharedService.updateSiteList(res);
		});

		this.mineService.getAllMinesMasters().subscribe(res => {
			this.sharedService.updatemineList(res);
		});

		this.transporterService.getAllTransporters().subscribe(res => {
			this.sharedService.updateTransporterList(res);
		});

		this.vehicleService.getAllVehicles().subscribe(res => {
			this.sharedService.updateVehicleList(res);
		});

		this.dcService.getAllDeliveryChallans().subscribe(res => {
			this.sharedService.updateDeliveryChallanList(res);
		});

		this.invoiceService.getAllInvoices().subscribe(res => {
			this.sharedService.updateInvoiceList(res);
		});

		this.warehouseService.getAllWarehouses().subscribe(res => {
			this.sharedService.updateWarehouseList(res);
		});
	}

	/**CompanyList */
	companyList() {
		this.comloading = true;
		this.companyService.getAllCompanyMasters().subscribe(companies => {
			this.companies = companies;
		}, error => {
			this.comloading = false;
			this.authNoticeService.setNotice('Opps server is down! or check your internet connection!', 'danger');
		});
		this.comloading = false;
	}

	onCompanyChange(CompanyMId) {
		if (CompanyMId > 0) {
			this._selectedCompany = this.companies.filter(c => c.CompanyMId === CompanyMId);
			this.sharedService.onChangeCompanyData(this._selectedCompany);
		}
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		// demo message to show
		// if (!this.authNoticeService.onNoticeChanged$.getValue()) {
		// 	const initialNotice = `Use account
		// 	<strong>${DEMO_PARAMS.USERNAME}</strong> and password
		// 	<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
		// 	this.authNoticeService.setNotice(initialNotice, 'info');
		// }

		this.loginForm = this.fb.group({
			CompanyMId: [DEMO_PARAMS.CompanyMId, Validators.compose([Validators.required])],
			username: [DEMO_PARAMS.USERNAME, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: [DEMO_PARAMS.PASSWORD, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			CompanyMId: controls['CompanyMId'].value,
			username: controls['username'].value,
			password: controls['password'].value
		};
		this.auth
			.login(authData.username, authData.password)
			.pipe(
				tap(user => {
					if (!user) {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
					} else {
						this.onCompanyChange(authData.CompanyMId);
						this.store.dispatch(new Login({ userID: +user.userID, authToken: user.access_token, logedCompany: this._selectedCompany }));
						this.getApiData();
						this.router.navigateByUrl('/'); // Main page
					}
				},
					error => {
						this.authNoticeService.setNotice('Invalid User name or Password', 'danger');
					}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.detectChanges();
				})
			)
			.subscribe();
	}
	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
}
