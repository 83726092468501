import { BaseModel } from '../../../_base/crud';

export class MinesMasterModel extends BaseModel {
	MinesMId: number;
	CompanyMId: number;
	MinesCode: number;
	MinesName: string;
	MinesOwnerName: string;
	MinesAddress: string;
	MinesPincode: string;
	MinesMobileNo: string;
	MinesPhoneNo: string;
	MinesFaxNo: string;
	MinesEmailId: string;
	MinesWebsite: string;
	MinesRefPerson1: string;
	MinesRefPerson1Mo: string;
	MinesRefPerson1EmailId: string;
	MinesRefPerson2: string;
	MinesRefPerson2Mo: string;
	MinesRefPerson2EmailId: string;
	MinesAddInfo1: string;
	MinesAddInfo2: string;
	MinesAddInfo3: number;
	MinesAddInfo4: number;
	Type: string;


	clear() {
		this.CompanyMId= undefined;
		this.MinesCode= undefined;
		this.MinesName= '';
		this.MinesOwnerName= '';
		this.MinesAddress= '';
		this.MinesPincode= '';
		this.MinesMobileNo= '';
		this.MinesPhoneNo= '';
		this.MinesFaxNo= '';
		this.MinesEmailId= '';
		this.MinesWebsite= '';
		this.MinesRefPerson1= '';
		this.MinesRefPerson1Mo= '';
		this.MinesRefPerson1EmailId= '';
		this.MinesRefPerson2= '';
		this.MinesRefPerson2Mo= '';
		this.MinesRefPerson2EmailId= '';
		this.MinesAddInfo1= '';
		this.MinesAddInfo2= '';
		this.MinesAddInfo3= undefined;
		this.MinesAddInfo4= undefined;
		this.Type= '';
	}
}
