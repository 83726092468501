import { QueryParamsModel } from '../../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../../_base/crud';
// Services
import { ProductsService } from '../_services/product.service';
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';
// State
import { AppState } from '../../../reducers';

// Actions
import {
    ProductActionTypes,
    ProductsPageRequested,
    ProductsPageLoaded,
    ManyProductsDeleted,
    OneProductDeleted,
    ProductActionToggleLoading,
    ProductsPageToggleLoading,
    ProductUpdated,
    ProductCreated,
    ProductOnServerCreated
} from '../_actions/product.action';
import { of } from 'rxjs';

@Injectable()
export class ProductEffects {
    showPageLoadingDistpatcher = new ProductsPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new ProductActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new ProductActionToggleLoading({ isLoading: false });

    constructor(private actions$: Actions,
        private productsService: ProductsService,
        private sharedService: ShareddataserviceService,
        private store: Store<AppState>
    ) { }

    @Effect()
    loadProductsPage$ = this.actions$.pipe(
        ofType<ProductsPageRequested>(ProductActionTypes.ProductsPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.productsService.findProducts(payload.page);

            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];

            this.sharedService.updateProductList(result.items);

            const pageLoadedDispatch = new ProductsPageLoaded({
                products: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteProduct$ = this.actions$
        .pipe(
            ofType<OneProductDeleted>(ProductActionTypes.OneProductDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.productsService.deleteProduct(payload.id);
            }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteProducts$ = this.actions$
        .pipe(
            ofType<ManyProductsDeleted>(ProductActionTypes.ManyProductsDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.productsService.deleteProducts(payload.ids);
            }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateProduct$ = this.actions$
        .pipe(
            ofType<ProductUpdated>(ProductActionTypes.ProductUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.productsService.updateProduct(payload.product.ProdCatMId, payload.product);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createProduct$ = this.actions$
        .pipe(
            ofType<ProductOnServerCreated>(ProductActionTypes.ProductOnServerCreated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.productsService.createProduct(payload.product).pipe(
                    tap(res => {
                        this.store.dispatch(new ProductCreated({ product: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );
}