export class Global {
    public static BASE_LOGIN_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/';
    public static BASE_CONTRACTOR_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/ContractorAPI/GetByCompanyMId/';
    public static BASE_CONTRACTOR_RATE_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/ContractorRateAPI/GetByCompanyMId/';
    public static BASE_CUSTOMER_RATE_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/CustomerRateAPI/GetByCompanyMId/';
    public static BASE_CUSTOMER_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/CustomerAPI/GetByCompanyMId/';
    public static BASE_DELIVERY_CHALLAN_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/DeliveryChallanAPI/GetByCompanyMId/';
    public static BASE_INVOICE_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/InvoiceAPI/GetByCompanyMId/';
    public static BASE_MINE_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/MinesAPI/GetByCompanyMId/';
    public static BASE_PRODUCT_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/ProductCateAPI/GetByCompanyMId/';
    public static BASE_SITE_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/SiteAPI/GetByCompanyMId/';
    public static BASE_TRANSPORTER_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/TransportAPI/GetByCompanyMId/';
    public static BASE_USER_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/UserAPI/GetByCompanyMId/';
    public static BASE_VEHICLE_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/VehicleAPI/GetByCompanyMId/';
    public static BASE_WAREHOUSE_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/WarehouseAPI/GetByCompanyMId/';
    public static BASE_USER_MSTER_BY_COMPANYMID = 'http://bhgsilicaapi2223.techfaiz.com/api/userapi/GetByCompanyMId/';

    public static BASE_CONTRACTOR_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/contractorapi/';
    public static BASE_TRANSPORT_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/transportapi/';
    public static BASE_CUSTOMERS_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/customerapi/';
    public static BASE_PRODUCT_MASTER_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/productcateapi/';
    public static BASE_SITE_MASTER_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/siteapi/';
    public static BASE_MINE_MASTER_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/minesapi/';
    public static BASE_COMPANY_MASTER_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/companyapi/';
    // public static BASE_CONTRACTOR_RATE_MASTER_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/contractorrateapi/';
    public static BASE_CONTRACTOR_RATE_MASTER_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/contractorrateapi/';
    // public static BASE_CUSTOMER_RATE_MASTER_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/customerrateapi/';
    public static BASE_CUSTOMER_RATE_MASTER_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/customerrateapi/';
    public static BASE_INVOICE_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/invoiceapi/';
    public static BASE_WAREHOUSE_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/warehouseapi/';
    public static BASE_DELIVERY_CHALLAN_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/deliverychallanapi/';
    //public static BASE_DASHBOARD_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/dashboardapi/';
    public static BASE_USER_MSTER_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/userapi/';
    public static BASE_USER_BY_TOKEN_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/userapi/getUserByToken';
    public static BASE_VEHICLE_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/vehicleapi/';
    public static BASE_UPLOAD_COMPANY_LOGO_IMAGE_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/CompanyAPI/UploadImage/';
    public static BASE_CONNECTION_STRING_ENDPOINT = 'http://bhgsilicaapi2223.techfaiz.com/api/ConnectionString/';

    // IsLoggedin
    public static _isLoggedIn: boolean = null;
    public static _userToken = '';
}
