// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { InvoiceActions, InvoiceActionTypes } from '../_actions/invoice.actions';
// Models
import { InvoiceModel } from '../_models/invoice.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface InvoiceState extends EntityState<InvoiceModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedInvoiceId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<InvoiceModel> = createEntityAdapter<InvoiceModel>({
    selectId: invoice => invoice.InvoiceId
});

export const initialInvoiceState: InvoiceState = adapter.getInitialState({
    invoiceForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedInvoiceId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function invoiceReducer(state = initialInvoiceState, action: InvoiceActions): InvoiceState {
    switch (action.type) {
        case InvoiceActionTypes.InvoicePageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedInvoiceId: undefined
            };
        }
        case InvoiceActionTypes.InvoiceActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case InvoiceActionTypes.InvoiceOnServerCreated: return {
            ...state
        };
        case InvoiceActionTypes.InvoiceCreated: return adapter.addOne(action.payload.invoice, {
            ...state, lastCreatedInvoiceId: action.payload.invoice.InvoiceId
        });
        case InvoiceActionTypes.InvoiceUpdated: return adapter.updateOne(action.payload.partialInvoice, state);
        case InvoiceActionTypes.OneInvoiceDeleted: return adapter.removeOne(action.payload.InvoiceId, state);
        case InvoiceActionTypes.InvoicePageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case InvoiceActionTypes.InvoicePageLoaded: {
            return adapter.addMany(action.payload.invoices, {
                ...initialInvoiceState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getInvoiceState = createFeatureSelector<InvoiceModel>('invoices');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
