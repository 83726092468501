// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { CustomerModel } from '../_models/customer.model';

export enum CustomerActionTypes {
    CustomerOnServerCreated = '[Edit Customer Dialog] Customer On Server Created',
    CustomerCreated = '[Edit Customer Dialog] Customer Created',
    CustomerUpdated = '[Edit Customer Dialog] Customer Updated',
    OneCustomerDeleted = '[Customer List Page] One Customer Deleted',
    CustomerPageRequested = '[Customer List Page] Customer Page Requested',
    CustomerPageLoaded = '[Customer API] Customer Page Loaded',
    CustomerPageCancelled = '[Customer API] Customer Page Cancelled',
    CustomerPageToggleLoading = '[Customer] Customer Page Toggle Loading',
    CustomerActionToggleLoading = '[Customer] Customer Action Toggle Loading'
}

export class CustomerOnServerCreated implements Action {
    readonly type = CustomerActionTypes.CustomerOnServerCreated;
    constructor(public payload: { customer: CustomerModel }) { }
}

export class CustomerCreated implements Action {
    readonly type = CustomerActionTypes.CustomerCreated;
    constructor(public payload: { customer: CustomerModel }) { }
}

export class CustomerUpdated implements Action {
    readonly type = CustomerActionTypes.CustomerUpdated;
    constructor(public payload: { 
        CustId: number,
        partialCustomer: Update<CustomerModel>, // For State update
        customer: CustomerModel 
    }) { }
}

export class OneCustomerDeleted implements Action {
    readonly type = CustomerActionTypes.OneCustomerDeleted;
    constructor(public payload: { CustId: number }) { }
}

export class CustomerPageRequested implements Action {
    readonly type = CustomerActionTypes.CustomerPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class CustomerPageLoaded implements Action {
    readonly type = CustomerActionTypes.CustomerPageLoaded;
    constructor(public payload: { customers: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class CustomerPageCancelled implements Action {
    readonly type = CustomerActionTypes.CustomerPageCancelled;
}

export class CustomerPageToggleLoading implements Action {
    readonly type = CustomerActionTypes.CustomerPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class CustomerActionToggleLoading implements Action {
    readonly type = CustomerActionTypes.CustomerActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type CustomerActions = CustomerOnServerCreated
    | CustomerCreated
    | CustomerUpdated
    | OneCustomerDeleted
    | CustomerPageRequested
    | CustomerPageLoaded
    | CustomerPageCancelled
    | CustomerPageToggleLoading
    | CustomerActionToggleLoading;
