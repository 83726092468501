// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { ContractorRateModel } from '../_models/contractorRate.model';

export enum ContractorRateActionTypes {
    ContractorRateOnServerCreated = '[Edit ContractorRate Dialog] ContractorRate On Server Created',
    ContractorRateCreated = '[Edit ContractorRate Dialog] ContractorRate Created',
    ContractorRateUpdated = '[Edit ContractorRate Dialog] ContractorRate Updated',
    ContractorRateStatusUpdated = '[ContractorRate List Page] ContractorRate Status Updated',
    OneContractorRateDeleted = '[ContractorRate List Page] One ContractorRate Deleted',
    ContractorRatePageRequested = '[ContractorRate List Page] ContractorRate Page Requested',
    ContractorRatePageLoaded = '[ContractorRate API] ContractorRate Page Loaded',
    ContractorRatePageCancelled = '[ContractorRate API] ContractorRate Page Cancelled',
    ContractorRatePageToggleLoading = '[ContractorRate] ContractorRate Page Toggle Loading',
    ContractorRateActionToggleLoading = '[ContractorRate] ContractorRate Action Toggle Loading'
}

export class ContractorRateOnServerCreated implements Action {
    readonly type = ContractorRateActionTypes.ContractorRateOnServerCreated;
    constructor(public payload: { contractorRate: ContractorRateModel }) { }
}

export class ContractorRateCreated implements Action {
    readonly type = ContractorRateActionTypes.ContractorRateCreated;
    constructor(public payload: { contractorRate: ContractorRateModel }) { }
}

export class ContractorRateUpdated implements Action {
    readonly type = ContractorRateActionTypes.ContractorRateUpdated;
    constructor(public payload: {
        ContractorRateId: number,
        partialContractorRate: Update<ContractorRateModel>, // For State update
        contractorRate: ContractorRateModel
    }) { }
}

export class ContractorRateStatusUpdated implements Action {
    readonly type = ContractorRateActionTypes.ContractorRateStatusUpdated;
    constructor(public payload: {
        contractorRate: ContractorRateModel[],
        ProductRate: number
    }) { }
}

export class OneContractorRateDeleted implements Action {
    readonly type = ContractorRateActionTypes.OneContractorRateDeleted;
    constructor(public payload: { ContractorRateId: number }) { }
}

export class ContractorRatePageRequested implements Action {
    readonly type = ContractorRateActionTypes.ContractorRatePageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class ContractorRatePageLoaded implements Action {
    readonly type = ContractorRateActionTypes.ContractorRatePageLoaded;
    constructor(public payload: { contractorRates: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class ContractorRatePageCancelled implements Action {
    readonly type = ContractorRateActionTypes.ContractorRatePageCancelled;
}

export class ContractorRatePageToggleLoading implements Action {
    readonly type = ContractorRateActionTypes.ContractorRatePageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ContractorRateActionToggleLoading implements Action {
    readonly type = ContractorRateActionTypes.ContractorRateActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type ContractorRateActions = ContractorRateOnServerCreated
    | ContractorRateCreated
    | ContractorRateUpdated
    | ContractorRateStatusUpdated
    | OneContractorRateDeleted
    | ContractorRatePageRequested
    | ContractorRatePageLoaded
    | ContractorRatePageCancelled
    | ContractorRatePageToggleLoading
    | ContractorRateActionToggleLoading;
