import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatFormFieldModule,
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material';

import { HttpModule } from '@angular/http';
import { CdkTableModule } from '@angular/cdk/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// UI
import { PartialsModule } from '../views/partials/partials.module';
//HighChart
import { HighchartsChartModule } from 'highcharts-angular';
//Dx Reporting
import { DxReportViewerModule } from 'devexpress-reporting-angular';
// Auth
import { ModuleGuard } from '../core/auth';
// Core => Utils
import {
  HttpUtilsService,
  TypesUtilsService,
  InterceptService,
  LayoutUtilsService
} from '../core/_base/crud';

//Services
import { CompanyMasterService } from '../core/masters/companymaster';
import { DeliveryChallanService } from '../core/transactions/deliverychallan';
import { InvoiceService } from '../core/transactions/invoicetransaction';
import { WarehouseService } from '../core/transactions/warehousetransaction';
import { ProductsService } from '../core/masters/product-master';
import { ContractorService } from '../core/masters/contractor';
import { CustomerService } from '../core/masters/customer';
import { ContractorRateService } from '../core/masters/contractorrate';
import { CustomerRateService } from '../core/masters/customerrate';
import { SiteService } from '../core/masters/site';
import { MinesMasterService } from '../core/masters/minesmaster';
import { TransporterService } from '../core/masters/transporter';
import { VehicleService } from '../core/masters/vehicle';
import { UserMasterService } from '../core/masters/usermaster';

// Shared
import {
  ActionNotificationComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
  UpdateRateDialogComponent
} from '../views/partials/content/crud';

import { NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    HttpModule,
    CdkTableModule,
    HttpClientModule,
    RouterModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatFormFieldModule,
    PartialsModule,
    NgbProgressbarModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    DxReportViewerModule
  ],
  exports: [
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatFormFieldModule,
    PartialsModule,
    NgbProgressbarModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    DxReportViewerModule
  ],
  declarations: [
  ],
  providers: [
    ModuleGuard,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        height: 'auto',
        width: '900px'
      }
    },
    TypesUtilsService,
    LayoutUtilsService,
    HttpUtilsService,
    CompanyMasterService,
    DeliveryChallanService,
    InvoiceService,
    WarehouseService,
    ProductsService,
    ContractorService,
    CustomerService,
    ContractorRateService,
    CustomerRateService,
    SiteService,
    MinesMasterService,
    TransporterService,
    VehicleService,
    UserMasterService,
  ],
  entryComponents: [
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    UpdateRateDialogComponent,
  ],
})
export class SharedModule { }

// {
//   provide: DateAdapter, deps: [MAT_DATE_LOCALE], useValue: 'en-in'
// }