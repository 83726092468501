// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { TransporterState } from '../_reducers/transporter.reducers';
import { TransporterModel } from '../_models/transporter.model';

export const selectTransporterState = createFeatureSelector<TransporterState>('transporters');

export const selectTransporterById = (TransportMId: number) => createSelector(
    selectTransporterState,
    transporterState => transporterState.entities[TransportMId]
);

export const selectTransporterPageLoading = createSelector(
    selectTransporterState,
    transporterState => transporterState.listLoading
);

export const selectTransporterActionLoading = createSelector(
    selectTransporterState,
    transporterState => transporterState.actionsloading
);

export const selectLastCreatedTransportMId = createSelector(
    selectTransporterState,
    transporterState => transporterState.lastCreatedTransportMId
);

export const selectTransporterShowInitWaitingMessage = createSelector(
    selectTransporterState,
    transporterState => transporterState.showInitWaitingMessage
);

export const selectTransporterInStore = createSelector(
    selectTransporterState,
    transporterState => {
        const items: TransporterModel[] = [];
        each(transporterState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: TransporterModel[] = httpExtension.sortArray(items, transporterState.lastQuery.sortField, transporterState.lastQuery.sortOrder);
        return new QueryResultsModel(result, transporterState.totalCount, '');
    }
);
