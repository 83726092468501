// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { WarehouseState } from '../_reducers/warehouse.reducers';
import { WarehouseModel } from '../_models/warehouse.model';

export const selectWarehouseState = createFeatureSelector<WarehouseState>('warehouses');

export const selectWarehouseById = (WarehouseMId: number) => createSelector(
    selectWarehouseState,
    warehouseState => warehouseState.entities[WarehouseMId]
);

export const selectWarehousePageLoading = createSelector(
    selectWarehouseState,
    warehouseState => warehouseState.listLoading
);

export const selectWarehouseActionLoading = createSelector(
    selectWarehouseState,
    warehouseState => warehouseState.actionsloading
);

export const selectLastCreatedWarehouseMId = createSelector(
    selectWarehouseState,
    warehouseState => warehouseState.lastCreatedWarehouseId
);

export const selectWarehouseShowInitWaitingMessage = createSelector(
    selectWarehouseState,
    warehouseState => warehouseState.showInitWaitingMessage
);

export const selectWarehouseInStore = createSelector(
    selectWarehouseState,
    warehouseState => {
        const items: WarehouseModel[] = [];
        each(warehouseState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: WarehouseModel[] = httpExtension.sortArray(items, warehouseState.lastQuery.sortField, warehouseState.lastQuery.sortOrder);
        return new QueryResultsModel(result, warehouseState.totalCount, '');
    }
);
