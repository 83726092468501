// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { CustomerRateState } from '../_reducers/customerRate.reducers';
import { CustomerRateModel } from '../_models/customerRate.model';

export const selectCustomerRateState = createFeatureSelector<CustomerRateState>('customerRates');

export const selectCustomerRateById = (CustRateId: number) => createSelector(
    selectCustomerRateState,
    customerRateState => customerRateState.entities[CustRateId]
);

export const selectCustomerRatePageLoading = createSelector(
    selectCustomerRateState,
    customerRateState => customerRateState.listLoading
);

export const selectCustomerRateActionLoading = createSelector(
    selectCustomerRateState,
    customerRateState => customerRateState.actionsloading
);

export const selectLastCreatedCustRateId = createSelector(
    selectCustomerRateState,
    customerRateState => customerRateState.lastCreatedCustRateId
);

export const selectCustomerRateShowInitWaitingMessage = createSelector(
    selectCustomerRateState,
    customerRateState => customerRateState.showInitWaitingMessage
);

export const selectCustomerRateInStore = createSelector(
    selectCustomerRateState,
    customerRateState => {
        const items: CustomerRateModel[] = [];
        each(customerRateState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CustomerRateModel[] = httpExtension.sortArray(items, customerRateState.lastQuery.sortField, customerRateState.lastQuery.sortOrder);
        return new QueryResultsModel(result, customerRateState.totalCount, '');
    }
);
